"use client";
import styles from "./index.module.css";
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from "../config.js";
import { useNavigate } from 'react-router-dom';
const host = config.api;

function Header({ navigate, balance, username, setToken }) {
    function logout() {
        Cookies.remove('token');
        setToken("");
        navigate("/login");
    }
    function toggleDropdown() {
        const dropdown = document.getElementById('dropdown-menu');
        const arrow = document.getElementById('dropdown-arrow');
        dropdown.style.display = dropdown.style.display === 'block' ? 'none' : 'block';
        arrow.textContent = dropdown.style.display === 'block' ? '▲' : '▼';
    }
    return ( // <a href="#">Join Telegram</a>
        <header className={styles.header}>
            <div className={styles.headerLeft}>GMS</div>
            <div className={styles.headerRight}>
                <div className={styles.balanceContainer}>
                    <span className={styles.plusIcon}>+</span>
                    <span style={{fontWeight: "600"}}>${balance}</span>
                </div>
                <div className={styles.userDropdown}>
                    <span className={styles.username}></span>
                    <span className={styles.dropdownArrow} id="dropdown-arrow" onClick={toggleDropdown}>▼</span>
                    <div className={styles.dropdownMenu} id="dropdown-menu">
                        <span className={styles.dropdownHeader}>{username}</span>
                        <a href="#" onClick={logout}>logout</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

function SideBar() {
    function toggleSidebar() {
        const sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle(styles.collapsed);
    }
    return (
        <div className={styles.sidebar} id="sidebar">
            <ul className={styles.menu}>
                <li><a href="#" className={`${styles.tab} ${styles.active}`} data-tab="overview">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-eye"></i></span>
                    <span className={styles.span}>Overview</span>
                </a></li>
                <li><a href="#" className={styles.tab} data-tab="dashboard">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-tachometer-alt"></i></span>
                    <span className={styles.span}>GMS</span>
                </a></li>
                <li><a href="#" className={styles.tab} data-tab="support">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-life-ring"></i></span>
                    <span className={styles.span}>Support</span>
                </a></li>
            </ul>        
            <div class="tabIndicator"></div>
            <button className={styles.hamburger} onClick={toggleSidebar}>☰</button>
        </div>
    )
}

function Panel({ setToken }) {
    const [username, setUsername] = useState('-');
    const [balance, setBalance] = useState('-');
    const navigate = useNavigate();
    
    async function get_request(path) {
        const token = Cookies.get('token');
        if (!token) {
            setToken("");
            navigate("/login");
        }
        try {
            const response = await axios.get(`${host}${path}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                Cookies.remove('token');
                setToken("");
                navigate("/login");
            } else {
                return error.response;
            }
        }
    }

    async function post_request(path, data) {
        const token = Cookies.get('token');
        if (!token) {
            setToken("");
            navigate("/login");
        }
        try {
            const response = await axios.post(`${host}${path}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            if (error.response?.status === 401) {
                Cookies.remove('token');
                setToken("");
                navigate("/login");
            } else {
                return error.response;
            }
        }
    }

    useEffect(() => {
        (async () => {
            let response, table;
            response = await get_request("/users/me");
            if (!response) {
                return;
            }
            setBalance(response.data.balance);
            setUsername(response.data.username);

            table = document.getElementById('transaction-table');
            table.innerHTML = '';
            response = await get_request("/invoice/list");
            if (!response) {
                return;
            }
            response.data.forEach(item => {
                const newRow = document.createElement('tr');
                const dateCell = document.createElement('td');
                dateCell.textContent = item.date;
                newRow.appendChild(dateCell);
            
                const amountCell = document.createElement('td');
                amountCell.textContent = `$${item.amount}`;
                newRow.appendChild(amountCell);
            
                const statusCell = document.createElement('td');
                statusCell.textContent = item.status;
                newRow.appendChild(statusCell);
            
                table.appendChild(newRow);
            })
            
        
            table = document.getElementById('account-list');
            table.innerHTML = '';
            response = await get_request("/sim/list");
            if (!response) {
                return;
            }

            response.data.forEach(holder => {
                const row = document.createElement('tr');

                const nameCell = document.createElement('td');
                nameCell.textContent = holder.name;
                nameCell.classList.add('holder-name-cell');
                nameCell.addEventListener('click', () => toggleDetails(row, holder));

                row.appendChild(nameCell);

                table.appendChild(row);
            });
        })();
    }, []);

    useEffect(() => {
        const tabs = document.querySelectorAll(`.${styles.tab}`);
        const sidebar = document.getElementById("sidebar");
        const indicator = document.createElement('div');
        const contents = document.querySelectorAll(`.tab-content`);
        let activeTab = document.querySelector(`.${styles.active}`);
        indicator.classList.add(`${styles.tabIndicator}`);
        sidebar.appendChild(indicator);

        function updateIndicator() {
            indicator.style.top = `${activeTab.offsetTop}px`;
            indicator.style.height = `${activeTab.offsetHeight}px`;
    
            if (sidebar.classList.contains('collapsed')) {
                indicator.style.left = '0';
            } else {
                indicator.style.left = '0';
            }
        }
        updateIndicator();

        tabs.forEach(tab => {
            tab.addEventListener('click', (e) => {
    
                activeTab.classList.remove(`${styles.active}`);
                activeTab = e.currentTarget;
                activeTab.classList.add(`${styles.active}`);
                updateIndicator();
                contents.forEach(content => {
                    if (content.id === `${activeTab.dataset.tab}-content`) {
                        content.style.display = 'block';
                    } else {
                        content.style.display = 'none';
                    }
                });
            });
        });

        contents.forEach(content => {
            if (content.id === `${activeTab.dataset.tab}-content`) {
                content.style.display = 'block';
            } else {
                content.style.display = 'none';
            }
        });
        
        
    }, []);

    
    function updateAccountTable(data) {
        const accountList = document.getElementById('account-list');
        accountList.innerHTML = '';

        data.forEach(holder => {
            const row = document.createElement('tr');

            const nameCell = document.createElement('td');
            nameCell.textContent = holder.holderName;
            nameCell.classList.add(`${styles.holderNameCell}`);
            nameCell.addEventListener('click', () => toggleDetails(row, holder));

            row.appendChild(nameCell);

            accountList.appendChild(row);
        });
    }

    function createAccountRow(account) {
        const accountRow = document.createElement('tr');

        const phoneCell = document.createElement('td');
        phoneCell.textContent = account.phoneNumber;
        accountRow.appendChild(phoneCell);

        const activationCell = document.createElement('td');
        const activationKeyShort = account.activationKey.substring(0, 8) + '...';
        activationCell.innerHTML = `<span class=${styles.activationKey}>${activationKeyShort}
            <button class=${styles.copyButton} onclick="copyActivationKey('${account.activationKey}')"><i class="fas fa-copy"></i></button>
        </span>`;
        accountRow.appendChild(activationCell);

        const dateCell = document.createElement('td');
        dateCell.textContent = account.creationDate;
        accountRow.appendChild(dateCell);

        return accountRow;
    }

    function generatePhoneNumber(data) {
        return "0120444444"
    }
    function generateActivationKey(data) {
        return "2111111111111"
    }

    function addNewNumbers(holder, accountsTable, count, addNumbersForm) {
        count = parseInt(count);

        if (isNaN(count) || count <= 0) {
            alert('Please select a valid number.');
            return;
        }

        if ([].length + count > 10) {
            alert('Total number of accounts cannot exceed 10.');
            return;
        }

        for (let i = 0; i < count; i++) {
            const newAccount = {
                phoneNumber: generatePhoneNumber(),
                activationKey: generateActivationKey(),
                creationDate: new Date().toISOString().split('T')[0]
            };

            [].push(newAccount);

            const newAccountRow = createAccountRow(newAccount);
            accountsTable.appendChild(newAccountRow);
        }
        addNumbersForm.parentNode.removeChild(addNumbersForm);
        // console.log(addNumbersForm.parentNode);

        if ([].length < 10) {
            const addButton = addNumbersForm.parentNode?.querySelector(`.${styles.addButton}`);
            if (addButton) {
                addButton.style.display = 'inline-block';
            }
        } else {
            const addButton = addNumbersForm.parentNode?.querySelector(`.${styles.addButton}`);
            if (addButton) {
                addButton.parentNode.removeChild(addButton);
            }
        }
    }

    function showAddNumbersForm(holder, accountsTable, addButton) {

    }

    function showLoadingIndicator(addButton, holder, accountsTable) {

        addButton.style.display = 'none';

        const loadingIndicator = document.createElement('div');
        loadingIndicator.classList.add(`${styles.loadingIndicator}`);
        loadingIndicator.innerHTML = `<div class=${styles.spinner}></div>`;
        addButton.parentNode.insertBefore(loadingIndicator, addButton.nextSibling);

        setTimeout(() => {
            loadingIndicator.parentNode.removeChild(loadingIndicator);
            showAddNumbersForm(holder, accountsTable, addButton);
        }, 3000);
    }

    function toggleDetails(row, holder) {

        if (row.nextSibling && row.nextSibling.classList.contains(`${styles.detailsRow}`)) {
            row.parentNode.removeChild(row.nextSibling);
            return;
        }

        const detailsRow = document.createElement('tr');
        detailsRow.classList.add(`${styles.detailsRow}`);
        const detailsCell = document.createElement('td');
        detailsCell.colSpan = 1;

        const detailsContainer = document.createElement('div');
        detailsContainer.classList.add(`${styles.detailsContainer}`);

        const planDisplay = document.createElement('div');
        planDisplay.classList.add(`${styles.planDisplay}`);
        planDisplay.textContent = `Plan: ${holder.plan}`;
        detailsContainer.appendChild(planDisplay);

        const accountsTable = document.createElement('table');
        accountsTable.classList.add(`${styles.detailsTable}`);

        const headerRow = document.createElement('tr');
        const headers = ['Phone Number', 'Activation Key', 'Creation Date'];
        headers.forEach(text => {
            const th = document.createElement('th');
            th.textContent = text;
            headerRow.appendChild(th);
        });
        accountsTable.appendChild(headerRow);

        (async () => {
            let response, loadingIndicator;
            
            detailsContainer.appendChild(accountsTable);

            detailsCell.appendChild(detailsContainer);
            detailsRow.appendChild(detailsCell);
            row.parentNode.insertBefore(detailsRow, row.nextSibling);

            loadingIndicator = document.createElement('div');
            loadingIndicator.classList.add(`${styles.loadingIndicator}`);
            loadingIndicator.innerHTML = `<div class=${styles.spinner}></div>`;
            // detailsContainer.appendChild(loadingIndicator);
            detailsContainer.parentNode.appendChild(loadingIndicator);
            response = await post_request("/sim/detail", {id: holder.id});
            detailsContainer.parentNode.removeChild(loadingIndicator);
            if (!response) {
                return;
            }
            response.data.forEach(account => {
                const accountRow = createAccountRow(account);
                accountsTable.appendChild(accountRow);
            });


            if (response.data.length < 10) {
                const addButton = document.createElement('button');
                addButton.textContent = '+';
                addButton.classList.add(`${styles.addButton}`);
                addButton.addEventListener('click', () => {
                    // showAddNumbersForm(holder, accountsTable, addButton);
                    // showLoadingIndicator(addButton, holder, accountsTable);
                    
                    addButton.style.display = 'none';
                    const addNumbersForm = document.createElement('div');
                    addNumbersForm.classList.add(`${styles.addNumbersForm}`);

                    const numberSelect = document.createElement('select');
                    numberSelect.classList.add(`${styles.numberSelect}`);

                    for (let i = 1; i <= (10 - response.data.length); i++) {
                        const option = document.createElement('option');
                        option.value = i;
                        option.textContent = i;
                        numberSelect.appendChild(option);
                    }

                    const createButton = document.createElement('button');
                    createButton.textContent = 'Create';
                    createButton.classList.add(`${styles.createButton}`);
                    createButton.addEventListener('click', () => {
                        addNewNumbers(holder, accountsTable, numberSelect.value, addNumbersForm);
                    });

                    addNumbersForm.appendChild(numberSelect);
                    addNumbersForm.appendChild(createButton);

                    addButton.parentNode.insertBefore(addNumbersForm, addButton.nextSibling);
                });
                detailsContainer.appendChild(addButton);
            }

        })();
    }

    function swapColumns(draggedColumnIndex, targetColumnIndex) {
        const table = document.getElementById("account-table");
    
        const headerRow = table.rows[0];
        headerRow.insertBefore(headerRow.children[draggedColumnIndex], headerRow.children[targetColumnIndex]);
        headerRow.insertBefore(headerRow.children[targetColumnIndex + 1], headerRow.children[draggedColumnIndex]);
    
        for (let i = 1; i < table.rows.length; i++) {
            const row = table.rows[i];
            row.insertBefore(row.children[draggedColumnIndex], row.children[targetColumnIndex]);
            row.insertBefore(row.children[targetColumnIndex + 1], row.children[draggedColumnIndex]);
        }
    }

    return (
        <div className={styles.container}>
            <Header navigate={navigate} balance={balance} username={username} setToken={setToken} />
            <SideBar />
            <div className={styles.mainContent}>
                <div className={styles.content}>
                    <div class="tab-content" id="overview-content">
                        <div className={styles.balanceBox}>
                            <span className={styles.span}>Credits</span>
                            <span className={`${styles.span} ${styles.balanceAmount}`}>${balance}</span>
                            <button className={styles.addCreditsBtn}>+ Add Credits</button>
                        </div>
                        <div className={styles.depositHistory}>
                            <h3>Deposit History</h3>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody id="transaction-table">
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" id="dashboard-content">
                        <div classname={styles.tableContainer}>
                            <table className={styles.table} id="account-table">
                                <thead>
                                    <tr>
                                        <th id="holder-name" onclick="sortByHolderName()" draggable="true" ondragstart="drag(event)" ondrop="drop(event)" ondragover="allowDrop(event)">Holder Name</th>
                                    </tr>
                                </thead>
                                <tbody id="account-list">
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" id="support-content">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Panel;