import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import config from "./config.js";
import Login from './login';
import Panel from './panel';

function App() {
  // eslint-disable-next-line
  const [token, setToken] = useState(Cookies.get('token') || '');

  useEffect(() => {
    const handleCookieChange = () => {
      const newToken = Cookies.get('token') || '';
      if (newToken !== token) {
        setToken(newToken);
      }
    }

    window.addEventListener('cookiechange', handleCookieChange);

    return () => {
      window.removeEventListener('cookiechange', handleCookieChange);
    }
  }, [token]);

  return (
    <Routes>
      <Route path="/login" element={token ? <Navigate to="/" /> : (
        <Login setToken={setToken} />
      )}/>
      <Route path="/" element={!token ? <Navigate to="/login" /> : (
        <Panel setToken={setToken}/>
            // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            //     <div>
            //         <button onClick={getUserData}>Get User Data</button>
            //         <button onClick={() => setToken('')}>Logout</button>
            //         <p>{message}</p>
            //         <p>{balance}</p>
            //     </div>
            // </div>
      )}/>
    </Routes>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);